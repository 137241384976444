import React, { useState, useEffect } from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import CtaPrimary from "../../../Base/CtaPrimary";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../../hooks/use-start-motion";
import check from "../../../../images/svg/checkmark.svg";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const SecondaryHeroTitleTwoCols = ({
  className,
  title,
  bodyData,
  subtext,
  image,
  ctaPrimary,
  with247Heading,
  animations,
  TitleSubCta,
  blockList,
}) => {
  const { ref, controls } = useStartMotion();
  const [subtextClasses, setSubtextClasses] = useState("");

  useEffect(() => {
    const classes = `
      absolute text-white transform -rotate-90
      ${image?.subtext?.length >= 20 ? "top-24" : "-top-16"}
      -right-28 md:-right-20 xl:-right-28
      lg:top-auto
      lg:bottom-52
    `;
    setSubtextClasses(classes);
  }, [image?.subtext?.length]);

  return (
    <section
      data-cy="secondary-hero-title-two-cols"
      className={`
        max-w-1440 w-full mx-auto px-0 lg:px-10
        ${className}
    `}
      ref={ref}
    >
      <div
        className={`
        bg-black rounded-large px-4 md:px-10 lg:px-28 pt-20 lg:pt-36
        grid grid-cols-1 xl:grid-cols-2 xl:gap-44
        pb-80
      `}
        // animate={controls}
        // initial={isMobileOnly || !animations ? "visible" : "hidden"}
        // variants={fadeInVariants}
        // transition={{ duration: 0.8 }}
      >
        <motion.div
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={fadeInVariants}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          <Heading
            level={2}
            injectionType={1}
            withBlueSymbol
            className="text-white"
          >
            {title}
          </Heading>

          {with247Heading && (
            <div className="text-white text-h2-small font-bold">
              24 <span className="text-primary">/</span> 7{" "}
              <span className="text-primary">/</span> 365
            </div>
          )}
          {TitleSubCta && (
            <CtaPrimary
              className="hidden md:block"
              title={TitleSubCta.title}
              target={TitleSubCta.target}
            />
          )}
        </motion.div>

        <div className="my-8 xl:my-0">
          {!bodyData && <p className="text-black-700 text-p">{subtext}</p>}

          {bodyData &&
            bodyData.map((item, idx) => (
              <div
                key={item.title}
                className={`${idx !== bodyData.length - 1 ? "mb-10" : ""}`}
              >
                <div className="text-h4 text-white font-bold mb-4 lg:mb-6">
                  {item.title}
                  <span className="text-primary text-h4 font-bold">.</span>
                </div>
                <p className="text-black-700 text-p">{item.subtext}</p>
              </div>
            ))}

          {blockList && (
            <ul className="p-0 mt-10">
              {blockList.map((item, i) => (
                <li key={`${i.toString()}__list`} className="mb-2">
                  <img className="inline-block mr-4" src={check} alt="" />
                  <p className="text-white inline-block font-bold">{item}</p>
                </li>
              ))}
            </ul>
          )}

          {TitleSubCta && (
            <CtaPrimary
              className="block md:hidden"
              title={TitleSubCta.title}
              target={TitleSubCta.target}
            />
          )}

          {ctaPrimary && (
            <CtaPrimary
              className="mt-6 lg:mt-10"
              title={ctaPrimary.title}
              target={ctaPrimary.target}
            />
          )}
        </div>
      </div>

      <motion.div
        className={`
          relative rounded-large max-w-1120
          mx-auto -mt-72 md:-mt-64 xl:-mt-56 px-4 md:px-10 xl:px-0
        `}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.8, duration: 0.8 }}
      >
        {image.mobileImageName && (
          <AppetizeImage
            className="rounded-large md:hidden image-mobile"
            imageName={image.mobileImageName}
          />
        )}
        <AppetizeImage
          className="rounded-large hidden md:block image-desktop"
          imageName={image.imageName}
        />
        {image.title && (
          <Heading
            className={`
          absolute text-white
          max-w-70-percent left-8 bottom-8
          md:left-16
          md:max-w-sm
          `}
            level={3}
            injectionType={3}
            withBlueSymbol
          >
            {image.title}
          </Heading>
        )}
        {image.subtext && (
          <div className={subtextClasses} style={{ width: "300px" }}>
            {image.subtext}
          </div>
        )}
      </motion.div>
    </section>
  );
};

SecondaryHeroTitleTwoCols.propTypes = {
  className: string,
  title: string.isRequired,
  subtext: string,
  bodyData: arrayOf(
    shape({
      title: string,
      subtext: string,
    })
  ),
  blockList: arrayOf(string),
  image: shape({
    imageName: string,
    mobileImageName: string,
    title: string,
    subtext: string,
  }).isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  with247Heading: bool,
  animations: bool,
  TitleSubCta: shape({
    title: string,
    target: string,
  }),
};

SecondaryHeroTitleTwoCols.defaultProps = {
  className: "",
  bodyData: null,
  subtext: "",
  ctaPrimary: null,
  TitleSubCta: null,
  with247Heading: false,
  animations: true,
  blockList: null,
};

export default SecondaryHeroTitleTwoCols;
